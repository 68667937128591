module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/tom/Workspace/tliu.net/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"tliu-net","short_name":"tliu-net","start_url":"/","background_color":"#007ecc","theme_color":"#007ecc","display":"minimal-ui","icon":"src/images/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d14f46c7e34542eb87f775de5dde1640"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
